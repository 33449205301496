module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Prochoice Evcil Hayvan Mamaları","short_name":"Prochoice Petfood","description":"Mutlu Kedi, Köpekler ve Ebevynler için Prochoice ","start_url":"/","background_color":"#FAFAFA","lang":"tr","theme_color":"#FAFAFA","display":"minimal-ui","icon":"src/images/prochoice-icon.png","localize":[{"start_url":"/en/","lang":"en","name":"Prochoice Petfood for Cats and Dogs","short_name":"Proochoice Petfood","description":"Prochoice for happy cats and dogs"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"f524442d545a1136f969d44d72a7d801"},
    },{
      plugin: require('../node_modules/gatsby-plugin-crisp-chat/gatsby-browser.js'),
      options: {"plugins":[],"websiteId":"f72f259e-5419-4a6b-986e-630dcf76e9a2","enableDuringDevelop":false,"defer":false,"enableImprovedAccessibility":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-breadcrumb/gatsby-browser.js'),
      options: {"plugins":[],"useAutoGen":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[],"googleAnalytics":{"trackingId":"","cookieName":"gatsby-gdpr-google-analytics","anonymize":true,"allowAdFeatures":false},"googleTagManager":{"trackingId":"GTM-TDNM3X2","cookieName":"gatsby-gdpr-google-tagmanager","dataLayerName":"dataLayer"},"facebookPixel":{"pixelId":"","cookieName":"gatsby-gdpr-facebook-pixel"},"environments":["production","development"]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
