// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-en-about-us-ingredients-js": () => import("./../../../src/pages/en/about-us/ingredients.js" /* webpackChunkName: "component---src-pages-en-about-us-ingredients-js" */),
  "component---src-pages-en-about-us-our-values-js": () => import("./../../../src/pages/en/about-us/our-values.js" /* webpackChunkName: "component---src-pages-en-about-us-our-values-js" */),
  "component---src-pages-en-about-us-pro-parent-js": () => import("./../../../src/pages/en/about-us/pro-parent.js" /* webpackChunkName: "component---src-pages-en-about-us-pro-parent-js" */),
  "component---src-pages-en-about-us-sustainability-js": () => import("./../../../src/pages/en/about-us/sustainability.js" /* webpackChunkName: "component---src-pages-en-about-us-sustainability-js" */),
  "component---src-pages-en-about-us-who-we-are-js": () => import("./../../../src/pages/en/about-us/who-we-are.js" /* webpackChunkName: "component---src-pages-en-about-us-who-we-are-js" */),
  "component---src-pages-en-blog-index-js": () => import("./../../../src/pages/en/blog/index.js" /* webpackChunkName: "component---src-pages-en-blog-index-js" */),
  "component---src-pages-en-contact-js": () => import("./../../../src/pages/en/contact.js" /* webpackChunkName: "component---src-pages-en-contact-js" */),
  "component---src-pages-en-exports-js": () => import("./../../../src/pages/en/exports.js" /* webpackChunkName: "component---src-pages-en-exports-js" */),
  "component---src-pages-en-index-js": () => import("./../../../src/pages/en/index.js" /* webpackChunkName: "component---src-pages-en-index-js" */),
  "component---src-pages-en-pdps-js": () => import("./../../../src/pages/en/pdps.js" /* webpackChunkName: "component---src-pages-en-pdps-js" */),
  "component---src-pages-en-products-cat-dry-food-index-js": () => import("./../../../src/pages/en/products/cat/dry-food/index.js" /* webpackChunkName: "component---src-pages-en-products-cat-dry-food-index-js" */),
  "component---src-pages-en-products-cat-index-js": () => import("./../../../src/pages/en/products/cat/index.js" /* webpackChunkName: "component---src-pages-en-products-cat-index-js" */),
  "component---src-pages-en-products-cat-litters-index-js": () => import("./../../../src/pages/en/products/cat/litters/index.js" /* webpackChunkName: "component---src-pages-en-products-cat-litters-index-js" */),
  "component---src-pages-en-products-cat-wet-food-index-js": () => import("./../../../src/pages/en/products/cat/wet-food/index.js" /* webpackChunkName: "component---src-pages-en-products-cat-wet-food-index-js" */),
  "component---src-pages-en-products-dog-dry-food-index-js": () => import("./../../../src/pages/en/products/dog/dry-food/index.js" /* webpackChunkName: "component---src-pages-en-products-dog-dry-food-index-js" */),
  "component---src-pages-en-products-dog-index-js": () => import("./../../../src/pages/en/products/dog/index.js" /* webpackChunkName: "component---src-pages-en-products-dog-index-js" */),
  "component---src-pages-en-products-dog-wet-food-index-js": () => import("./../../../src/pages/en/products/dog/wet-food/index.js" /* webpackChunkName: "component---src-pages-en-products-dog-wet-food-index-js" */),
  "component---src-pages-en-products-index-js": () => import("./../../../src/pages/en/products/index.js" /* webpackChunkName: "component---src-pages-en-products-index-js" */),
  "component---src-pages-en-terms-of-use-js": () => import("./../../../src/pages/en/terms-of-use.js" /* webpackChunkName: "component---src-pages-en-terms-of-use-js" */),
  "component---src-pages-hakkimizda-biz-kimiz-js": () => import("./../../../src/pages/hakkimizda/biz-kimiz.js" /* webpackChunkName: "component---src-pages-hakkimizda-biz-kimiz-js" */),
  "component---src-pages-hakkimizda-degerlerimiz-js": () => import("./../../../src/pages/hakkimizda/degerlerimiz.js" /* webpackChunkName: "component---src-pages-hakkimizda-degerlerimiz-js" */),
  "component---src-pages-hakkimizda-icerikler-js": () => import("./../../../src/pages/hakkimizda/icerikler.js" /* webpackChunkName: "component---src-pages-hakkimizda-icerikler-js" */),
  "component---src-pages-hakkimizda-pro-ebeveyn-js": () => import("./../../../src/pages/hakkimizda/pro-ebeveyn.js" /* webpackChunkName: "component---src-pages-hakkimizda-pro-ebeveyn-js" */),
  "component---src-pages-hakkimizda-surdurulebilirlik-js": () => import("./../../../src/pages/hakkimizda/surdurulebilirlik.js" /* webpackChunkName: "component---src-pages-hakkimizda-surdurulebilirlik-js" */),
  "component---src-pages-iletisim-js": () => import("./../../../src/pages/iletisim.js" /* webpackChunkName: "component---src-pages-iletisim-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kullanim-kosullari-js": () => import("./../../../src/pages/kullanim-kosullari.js" /* webpackChunkName: "component---src-pages-kullanim-kosullari-js" */),
  "component---src-pages-kvkk-js": () => import("./../../../src/pages/kvkk.js" /* webpackChunkName: "component---src-pages-kvkk-js" */),
  "component---src-pages-urunler-index-js": () => import("./../../../src/pages/urunler/index.js" /* webpackChunkName: "component---src-pages-urunler-index-js" */),
  "component---src-pages-urunler-kedi-index-js": () => import("./../../../src/pages/urunler/kedi/index.js" /* webpackChunkName: "component---src-pages-urunler-kedi-index-js" */),
  "component---src-pages-urunler-kedi-kumlar-index-js": () => import("./../../../src/pages/urunler/kedi/kumlar/index.js" /* webpackChunkName: "component---src-pages-urunler-kedi-kumlar-index-js" */),
  "component---src-pages-urunler-kedi-kuru-mamalar-index-js": () => import("./../../../src/pages/urunler/kedi/kuru-mamalar/index.js" /* webpackChunkName: "component---src-pages-urunler-kedi-kuru-mamalar-index-js" */),
  "component---src-pages-urunler-kedi-yas-mamalar-index-js": () => import("./../../../src/pages/urunler/kedi/yas-mamalar/index.js" /* webpackChunkName: "component---src-pages-urunler-kedi-yas-mamalar-index-js" */),
  "component---src-pages-urunler-kopek-index-js": () => import("./../../../src/pages/urunler/kopek/index.js" /* webpackChunkName: "component---src-pages-urunler-kopek-index-js" */),
  "component---src-pages-urunler-kopek-kuru-mamalar-index-js": () => import("./../../../src/pages/urunler/kopek/kuru-mamalar/index.js" /* webpackChunkName: "component---src-pages-urunler-kopek-kuru-mamalar-index-js" */),
  "component---src-pages-urunler-kopek-yas-mamalar-index-js": () => import("./../../../src/pages/urunler/kopek/yas-mamalar/index.js" /* webpackChunkName: "component---src-pages-urunler-kopek-yas-mamalar-index-js" */),
  "component---src-templates-blog-post-index-js": () => import("./../../../src/templates/BlogPost/index.js" /* webpackChunkName: "component---src-templates-blog-post-index-js" */),
  "component---src-templates-product-detail-index-js": () => import("./../../../src/templates/ProductDetail/index.js" /* webpackChunkName: "component---src-templates-product-detail-index-js" */)
}

